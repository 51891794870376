import React, { useState } from "react";
import Report1099 from "./reportFiles/Report1099";
import StaffTransactions from "./reportFiles/StaffTransactions";

const ReportGui = () => {
    const [activeComponent, setActiveComponent] = useState("home");
    const [selectedAuthorSlug, setSelectedAuthorSlug] = useState("");
    const [selectedStaffSlug, setSelectedStaffSlug] = useState("");

    const renderComponent = () => {
        switch (activeComponent) {
            case "report1099":
                return <Report1099 setActiveComponent={setActiveComponent} />;
            default:
                return <div>Welcome to the Report Dashboard</div>;
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={() => setActiveComponent("report1099")}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    1099
                </button>
            </div>
            {renderComponent()}
        </div>
    );
};

export default ReportGui;
