import React, { useState, useEffect } from "react";
import { getAuthorsWithTransactions } from "../../services/authorService";
import {
    getStaffMembers,
    getTransactionsByStaff,
} from "../../services/staffService";

const Report1099 = () => {
    const [paymentsByAuthor, setPaymentsByAuthor] = useState({});
    const [staffPayments, setStaffPayments] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        fetchPayments();
    }, []);

    const fetchPayments = async () => {
        setLoading(true);
        try {
            const authorsWithTransactions = await getAuthorsWithTransactions();
            const lastYear = new Date().getFullYear() - 1;
            const paymentsData = {};

            authorsWithTransactions.forEach((author) => {
                const payments = author.transactions.filter(
                    (transaction) =>
                        transaction.description
                            .toLowerCase()
                            .includes("payment") &&
                        new Date(transaction.date).getFullYear() === lastYear
                );

                let totalPayments = 0;
                payments.forEach((payment) => {
                    const amount = parseFloat(
                        payment.amount.replace(/[$,]/g, "")
                    );
                    // Simply negate the amount and add it to totalPayments:
                    totalPayments += -amount;
                });

                if (totalPayments > 0) {
                    paymentsData[author.slug] = {
                        authorName: author.name,
                        totalPayments: totalPayments,
                    };
                }
            });

            const staffMembers = await getStaffMembers();
            const staffPaymentsData = {};

            for (const staff of staffMembers) {
                const transactions = await getTransactionsByStaff(staff.id);
                const payments = transactions.filter(
                    (transaction) =>
                        transaction.description
                            .toLowerCase()
                            .includes("payment") &&
                        new Date(transaction.date).getFullYear() === lastYear
                );

                let totalPayments = 0;
                payments.forEach((payment) => {
                    totalPayments += -parseFloat(
                        payment.amount.replace(/[$,]/g, "")
                    );
                });

                if (totalPayments > 0) {
                    staffPaymentsData[staff.id] = {
                        staffName: staff.name,
                        totalPayments: totalPayments,
                    };
                }
            }

            setPaymentsByAuthor(paymentsData);
            setStaffPayments(staffPaymentsData);
        } catch (err) {
            setError("Failed to fetch payments: " + err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <div>Loading payments report...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h3>Payments to Authors - {new Date().getFullYear() - 1}</h3>
            <table>
                <thead>
                    <tr>
                        <th>Author</th>
                        <th>Total Payments</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(paymentsByAuthor).map(
                        ([authorSlug, { authorName, totalPayments }]) => (
                            <tr key={authorSlug}>
                                <td>{authorName} </td>
                                <td> ${totalPayments.toFixed(2)}</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
            <h3>Payments to Staff - {new Date().getFullYear() - 1}</h3>
            <table>
                <thead>
                    <tr>
                        <th>Staff</th>
                        <th>Total Payments</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(staffPayments).map(
                        ([staffSlug, { staffName, totalPayments }]) => (
                            <tr key={staffSlug}>
                                <td>{staffName} </td>
                                <td> ${totalPayments.toFixed(2)}</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Report1099;
